@import url('@fontsource/rubik');
@import url('@fontsource/roboto');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 22px;
}

body {
  min-height: 100vh;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

header {
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  font-family: Roboto;
}

.header-logo {
  height: auto;
  width: 100px;
  max-width: 100%;
  align-self: flex-start;
}

.header-title {
  font-size: 1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

.header-subtitle {
  font-size: .5rem;
  color: #62615C;
  -webkit-font-smoothing: antialiased;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.splash-container {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  justify-content: center;
  text-align: center;
}

.splash-image {
  background-image: url('https://storage.googleapis.com/marchelocal.appspot.com/site-images/Foto-legumes-na-mesa-300x300.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: luminosity;
  background-position: center;
  color: white;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-size: 0.8em;
  font-weight: 700;
  gap: 0;
}

.splash-text {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-family: "Roboto";
  font-size: .75rem;
  line-height: 1.5;
}


.instructions-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.5em;
  width: 100%;
  align-items: stretch;
}

.text-box {
  background-color: #BCA371;
  width: 75;
  min-width: 75px;
  /* adjust this value to change the maximum width of the boxes */
  padding: .25rem;
  /* adjust this value to change the padding inside the boxes */
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  flex: 1 0 calc(30% - 50px);
  display: flex;
  flex-direction: column;
}

.text-box-item-icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 1.5rem;
  width: 100%;
  padding: .2em;
  font-size: x-large;
}

.text-box-item-header {
  height: 1.5rem;
  width: 100%;
  text-align: center;
  font-size: small;
}

.text-box-item-text {
  width: 100%;
  text-align: center;
  font-size: x-small;
  color: #62615C;
}

.text-box-horizontal-bar {
  width: 50%;
  height: 1px;
  background-color: black;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.threesteps_container {
  width: 100%;
}

.threesteps_title {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #DBD5A9;
  font-size: medium;
  padding: 1em;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Roboto";
  margin-top: 1em;
}

.threesteps_container_step {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: medium;
  padding: .5em;
  line-height: 1.5;
}

.threesteps_container_icon {
  display: flex;
  margin-right: 10px;
  align-items: center;

}

.product-list-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #DBD5A9;
  font-size: medium;
  padding: 1em;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Roboto";
  margin-top: 1em;
}

.searchForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0.25rem;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.searchForm label {
  position: absolute;
  left: -99999px;
}

.searchForm input[type='text'] {
  flex-grow: 1;
  max-width: 100%;
  min-height: 36px;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  outline: none;
}

.button-array {
  width: 100%;
  margin-left: .2rem;
  display: flex;
  flex-direction: row;
}

button {
  min-height: 36px;
  padding: 0.25rem;
  font-size: 0.5rem;
  background-color: #F3F1E2;
  color: black;
  cursor: pointer;
  border: 0px;
  box-shadow: .2rem .2rem .2rem gray;
  margin-bottom: 0.5rem;
  margin-right: 0.2rem;
  flex-grow: 1;
  flex-shrink: 1;
}

@media (max-width: 400px) {
  button {
    font-size: 0.5rem;
  }
}

@media (min-width: 600px) {
  button {
    font-size: 0.7rem;
  }
}

@media (min-width: 800px) {
  button {
    font-size: 0.8rem;
  }
}


.buttonActive {
  background-color: #DBD5A9;
}

button:focus,
button:hover,
button:active {
  color: black;
  background-color: #DBD5A9;
}


ul {
  width: 100%;
  list-style: none;
  padding: 0 0.25rem 0.25rem;
}

ul li::before {
  content: "\200B";
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 0.25rem 0;
  background-color: #DBD5A9;
}

.borderLabelPrix {
  position: relative;
  border: 1px solid gray;
  border-radius: 0.25rem;
  padding: 0.2rem;
}

.borderLabelPrix::before {
  font-size: x-small;
  content: "prix";
  position: absolute;
  top: -8px;
  left: 10px;
  background: #DBD5A9;
  padding: 0px 5px;
}

.borderLabelTotalPrix {
  position: relative;
  border: 1px solid gray;
  border-radius: 0.25rem;
  padding: 0.2rem;
}

.borderLabelTotalPrix::before {
  font-size: x-small;
  content: "total";
  position: absolute;
  top: -8px;
  left: 8px;
  background: #DBD5A9;
  padding: 0px 5px;
}

.borderLabelQuantity {
  position: relative;
  border: 1px solid gray;
  border-radius: 0.25rem;
  padding: 0.2rem;
}

.borderLabelQuantity::before {
  font-size: x-small;
  content: "total";
  position: absolute;
  top: -8px;
  left: 8px;
  background: #DBD5A9;
  padding: 0px 5px;
}

.form-border {
  border: 1px solid #BCA371;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  width: 98%;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-size: 0.8em;
}

.thank-you-title,
.form-title {
  font-size: 1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  margin: 0.25em;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.5;
}

.form-text {
  font-size: .75rem;
  -webkit-font-smoothing: antialiased;
  margin: 0.25em;
  line-height: 1.75;
  color: #62615C;
  text-align: justify;
  font-size: 16px;
}

input[type='radio'] {
  accent-color: #32965D;
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="textarea"],
.form-container textarea,
.form-container select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form-container textarea {
  background-color: #F0F2F6;
  font-size: .5rem;
  font-size: 16px;
}

.form-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #68A357;
  color: white;
  cursor: pointer;
  width: auto;
  display: inline-block;
}

.form-container button:hover {
  background-color: #32965D;
}

.panier-title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  margin: 0.25em;
  line-height: 1.5;
  padding-left: .5em;
}

table {
  width: 90%;
  border-collapse: collapse;
  margin: .25em;
  padding: .25em;
  margin-left: auto;
  margin-right: auto;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0.25em;
  text-align: left;
  font-size: .5rem;
  -webkit-font-smoothing: antialiased;
  color: #62615C;
  font-family: Roboto;
}

th {
  background-color: #BCA371;
  color: white;
  font-size: .5rem;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto;
}

table td:nth-child(2),
table td:nth-child(3),
table td:nth-child(4) {
  text-align: right;
}

tfoot {
  background-color: #F0F2F6;
}

tfoot tr:last-child td {
  font-weight: bold;
}


footer {
  width: 100%;
  padding: 0.25em;
  background-color: #BCA371;
  color: aliceblue;
  display: grid;
  place-content: center;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #68A357;
  color: white;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 2em;
}

.submit-button:hover {
  background-color: #32965D;
}

.submit-button:disabled {
  background-color: #D3D3D3;
  cursor: not-allowed;
}

.thank-you-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #68A357;
  color: white;
  cursor: pointer;
  width: auto;
  display: inline-block;
}

.thank-you-button:hover {
  background-color: #32965D;
}

.thank-you-text {
  font-size: .75rem;
  -webkit-font-smoothing: antialiased;
  margin: 0.25em;
  line-height: 1.75;
  color: #62615C;
  text-align: justify;
  margin: 1.5em;
}

.thank-you-title {
  font-size: 1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  margin: 0.25em;
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.5;
  text-align: center;
}